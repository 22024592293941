<template>
  <v-container>
    <div id="nav">
      <Nav/>
    </div>
    <v-row dense>
      <v-col/>
      <v-col cols="8">
        <div align="center">
          <h1 class="v-heading ">{{ message.name }}</h1>
          <p>
          <p class="mx-auto">{{ message.comment }}</p>
        </div>
          <div v-for="item in message.items" :key="item">
            <v-card
                max-width="1000"
                class="mx-auto"
                :to="item.url"
            >
              <v-card-title class="headline">
                {{ item.name }}
              </v-card-title>
            </v-card>
            <br>
          </div>
      </v-col>
      <v-col/>
    </v-row>
  </v-container>
</template>

<script>
import Nav from "@/components/Nav";

export default {
  name: "JiaochenList",
  components: {
    Nav
  },
  data: function () {
    return {
      message: "",
    }
  },
  methods: {
    fetchData() {
      this.axios
          //.get('../assets/data/' + this.$route.params.table + '.json')
          .get('https://data.yang2000ling.com/data/article.json')
          .then(response => (this.message = response.data))
          .catch(error => console.log(error))
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>